import React from "react"
import Seo from "./seo"
import Header from "./header";
import {Logo} from "./Logo";
import {graphql, Link, useStaticQuery} from "gatsby";
import ExternalLink from "./ExternalLink";

const Layout = ({ title, children, description }) => {

  const { sanityGeneral: general } = useStaticQuery(
    graphql`
      query {
        sanityGeneral(_id: {eq: "general"}) {
          footerLinks {
            _rawReference(resolveReferences: {maxDepth: 5})
          }
          novitalisUrl {
            href
            blank
          }
          name
          copyrightHolder
        }
      }
    `
  )

  return (
    <>
      <Seo title={title} description={description}/>
      <div className={"h-full overflow-hidden"}>
        <div className="min-h-screen flex flex-col items-stretch">
          <div className="container px-4">
            <Header/>
          </div>
          <div className="flex-grow flex flex-col">
            <div className="container py-8 flex-grow px-4">
              <main>{children}</main>
            </div>
            <footer className="bg-gradient-to-r from-secondary to-primary text-white">
              <div className="container flex justify-between h-full py-10 px-4">
                <ExternalLink href={general.novitalisUrl.href} isBlank={general.novitalisUrl.blank}>
                  <Logo name={'novitalis'}/>
                </ExternalLink>
                <div className={"flex flex-col lg:flex-row items-end lg:items-center justify-end gap-2 lg:gap-8"}>
                  <div className={"flex flex-col sm:flex-row gap-2 sm:gap-8 items-end sm:items-center lowercase"}>
                    {
                      general.footerLinks.map((footerLink, index) => {
                        const href = `/${footerLink._rawReference.slug?.current || ''}`;
                        const label = footerLink._rawReference.title || 'Home';
                        return (
                          <Link key={`footer-${index}`} to={href}>{label}</Link>
                        )
                      })
                    }
                  </div>
                  <div className={"mt-4 lg:mt-0 lg:ml-4 text-right"}>© {new Date().getFullYear()} {general.copyrightHolder}</div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
}


export default Layout
