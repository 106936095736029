import React from "react";
import { StaticImage } from "gatsby-plugin-image"

export function Logo({name}) {
  if (name === 'novitalis') {
    return <StaticImage src="../images/logo-novitalis.png"
                        alt="Novitalis"
                        placeholder="blurred"
                        layout="fixed"
                        width={125}/>;
  }
  return <StaticImage src="../images/logo.png"
                      alt="R-Squared Talent Solutions"
                      placeholder="blurred"
                      layout="fixed"
                      width={200}/>;
}
