import {Link} from "gatsby";
import React from "react";
import {Logo} from "./Logo";

const Header = () => {

  return (
    <header>
      <div className="box-border flex items-center py-4">
        <Link className="" to={'/'}>
          <Logo/>
        </Link>
      </div>
    </header>
  );
}

export default Header;
